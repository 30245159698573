<template>
  <div>
    <div class="row">
      <div class="col s12 l6 xl12"><Radar :radarImage="props.radarModel"></Radar></div>
      
      <div class="col s12 l3 xl6"><Sigchart :sigImage="props.sigArea"></Sigchart></div>
      <div class="col s12 l3 xl6"><Map></Map></div>
    </div>
  </div>
</template>

<script setup>
import Radar from './Radar.vue';
import Sigchart from './Sigchart.vue';
import Map from './Map.vue';
import { watchEffect } from 'vue';

const props = defineProps({
  radarModel: Object,
  sigArea: Object,
});

watchEffect(()=>{
  console.log('change!');
});

</script>

<style>

</style>