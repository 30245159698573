<script setup>
//import Navbar from "./Navbar.vue";
import { tafValidToDate } from '@/helpers';
import Airport from './Airport.vue';
import Imagery from './Imagery.vue';
import router from '@/router';
import { socket, subscribeTo, state as socketState, restartSocket } from '@/socket';

import { ref, onMounted, watchEffect, inject, provide} from 'vue';


//inject global properties
const API_URL = inject('API_URL');
const STORAGE_KEY = inject('STORAGE_KEY');
const auth = inject('auth');


let initLocalStorage = null;
try {
    initLocalStorage = JSON.parse(localStorage.getItem(STORAGE_KEY));
} catch(err){
    console.error('error with local stored data:',err);
    console.log('clearing data for further usage');
    localStorage.removeItem(STORAGE_KEY);
    initLocalStorage = null;
}
//if no local storage is find, set defaults:
if (initLocalStorage == null){
    const defaultAirports = [];
    ['enkr'].forEach(el=>{
        defaultAirports.push(airportReferanceObject(el));
    });
    initLocalStorage = {
        radar: {
            site: 'finnmark',
            lastUpdate: new Date()
        },
        airports: defaultAirports
    }
}

const radarImage = ref({
    site: (initLocalStorage.radar.site || 'finnmark'),
    lastUpdate: new Date()
});
const airports = ref(initLocalStorage.airports);
const addICAO = ref();
const sigmets = ref([]);

provide('sigmets', sigmets);



function airportReferanceObject(apt='ZZZZ') {
    return ({
        ICAO: apt,
        metar: {
            latest: {},
            history: [],
        },
        taf: {
            latest: {},
            history: [],
        },
        windShear: '',
        lastUpdate: Date.now()
    });
}

//initial airport data fetching:
async function initializeAirports(){
    airports.value.forEach((apt,i,arr)=>{
        updateAirport(apt.ICAO).then(data => {
            if(data){
                subscribeToAirport(apt.ICAO);
                arr[i]=data;
            } else {
                console.log('no airport',data);
            }
        });
    });
    //load sig/airmets:
    fetchSigmet().then(data=>{
        if(data){
            sigmets.value = data;
            //console.log(sigmets.value);
        }
    })

    //reload token
    refreshToken();
}

function refreshToken(){
 try {
    fetchAPI('refreshtoken').then(resp =>{
        if(resp){
            if(resp.authenticated){
                auth.value.token = resp.token;
                auth.value.expires = resp.expires;
                socketState.authToken = resp.token;
                restartSocket(auth.value.token);
                socket.connect();
                console.log('reauthenticated',auth.value);
            }
        }
    }).catch(err => {
        console.error('error with token,',err);
    });
 } catch(err){
    console.error('error refershing token', err);
 }
}


function subscribeToAirport(icao){
    if(icao){
        subscribeTo([icao]);
        socket.on(icao,resp=>{
            airports.value.forEach((apt,i,arr)=>{
                if(apt.ICAO == icao){
                    //console.log(resp);
                    if(resp[0]=='metar'){
                        arr[i].metar.latest = resp[1][0];
                        //arr[i].metar.history.unshift(resp[1][0]);
                    } else if(resp[0]=='taf'){
                        arr[i].taf.latest = resp[1][0];
                        //arr[i].taf.history.unshift(resp[1][0]);
                    } else if(resp[0]=='windshear'){
                        console.log('ws:',resp[1]);
                        arr[i].windShear = resp[1];
                    }
                    arr[i].lastUpdate = Date.now();
                    socketState.lastPing = Date.now();
                }
            });
            console.log('update to: '+icao,'data;',resp);
        });
    }
}


async function updateAirport(icao) {
    const ICAO = icao.toUpperCase();
    return Promise.all([
        fetchAirportData(ICAO),
        fetchMetar(ICAO),
        fetchTaf(ICAO),
        fetchWindshear(ICAO)
    ]).then(res => {
        if(!res[0]){
           //invalid airport
            M.toast({html:'Invalid airport ICAO code'+ icao});
            return false;
        }
        //populate with airport data
        let aptData = res[0];
        aptData.lastUpdate = Date.now();
        //then add metar & TAF
        if(!aptData.hasOwnProperty('metar')){
            aptData.metar = {history:[],latest:''};
        }
        if(!aptData.hasOwnProperty('taf')){
            aptData.taf = {history:[],latest:''};
        }
        if(!aptData.hasOwnProperty('windShear')){
            aptData.windShear = false;
        }
        if(res[1]) {
            //newst on top, and update latest
            aptData.metar.history.unshift(res[1][0]);
            aptData.metar.latest = res[1][0];
        }
        if(res[2]){
            aptData.taf.history.unshift(res[2][0]);
            aptData.taf.latest = res[2][0];
        }
        if(res[3][0] != undefined){
            aptData.windShear = res[3][0];
            //console.log(res[3][0]);
        }
        if(!res[1] || !res[2]){
            console.log('no metar and/or taf recieved',icao);
        }
        return aptData;
    }).catch(err => {
        console.error('error Fetching',err);
    });
}


const fetchAirportData = async (icao) => {
    const path = `airports/${icao}`;
    return fetchAPI(path).then(res=>{
        //console.log(res);
        return res;
    }).catch(err=>{
        console.error('issues',err);
        return {ICAO: icao};
    });
}


const fetchMetar = async (icao,restr = true,debug = false) => {
    if(icao=='TEST'){
        restr=true,
        debug=true;
        icao='ENBL';
    }
    const path = `metar/fetch/${icao}?restrictions=${restr}&debug=${debug}`;
    return fetchAPI(path);
}

const fetchTaf = async (icao,restr = true, debug = false) => {
    const path = `taf/fetch/${icao}?restrictions=${restr}&debug=${debug}`;
    return fetchAPI(path);
}

const fetchWindshear = async (icao) => {
    const path = `windshear/${icao}`;
    return fetchAPI(path);
}
const fetchSigmet = async (icao) => {
    const path = `sigmets`;
    return fetchAPI(path);
}

let aptBtn = ref({
    bol: false,
    class: 'add'
});

const fetchAPI = async (path, method="GET") => { //standarized api call returning json status or false;
    try{
        const url = `${API_URL}/${path}`;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+auth.value.token
        }
        const options = {
            method: method,
            headers: headers

        }
        return await (await fetch(url,options)
        .then(res=>{
            if(res.ok){
                return res.json();
            } else if(res.status==401){
                auth.value = false;
            } else {
                return false;
            }
        }).catch(err=>{
            throw err;
        }));
    } catch(err){
        console.error('fetching error:', err);
        return NULL;
    }
}

//add new airport button logic
function addAptBtn(event){
    aptBtn.value.bol = !aptBtn.value.bol;
    if(aptBtn.value.bol) {
        aptBtn.value.class = 'save';
    } else {
        aptBtn.value.class= 'add';
        if(addICAO.value?.length==4){
            let newApt = airportReferanceObject(addICAO.value);
            updateAirport(addICAO.value).then(data => {
                //console.log(data);
                if(data){
                    subscribeTo([data.ICAO]);
                    addICAO.value = '';
                    airports.value.push(data);
                }
            });
        }
    }
}


//user select airport from a predefined list
const predifinedList = ref([]);
function loadPredefined(action,obj=[]) {
    if(action=='init' && predifinedList.value.length<1){
        console.log('loading predefined airports');
        fetchAPI('baseSets').then(res=>{
            if(!res){
                throw ('error loading list',res);
            } else {
                //console.log('list:',res);
                predifinedList.value=res;
            }
        }).catch(err=>{
            console.log('issus fetching list:',err);
        });
    } else if (action=='populate' && obj){
        console.log('populate',obj);
        airports.value = [];
        obj.airports.forEach(el=>{
            airports.value.push(airportReferanceObject(el));
        });
        initializeAirports();
        radarImage.value.site = obj.radarSite;
        radarImage.value.lastUpdate = Date.now();
    } else {
        console.log(action,obj.airports);
    }
}


function deleteAirport(index) {
    airports.value.splice(index,1);
}


onMounted(()=>{
    initializeAirports();
    let dropElm = document.querySelector('.dropdown-trigger');
    M.Dropdown.init(dropElm,{});

    //handle new sigmets:
    socket.on("sigmet",(resp)=>{
        const i = sigmets.value
            .map(smet=>{
                if(smet.hasOwnProperty('ID')){
                    return smet.ID; 
                } else {
                    return null;
                }
            }).findIndex((elm)=> elm===resp.ID);
        if(i==-1){
            sigmets.value.push(resp);
            console.log('sigmet added', resp.ID);
        } else {
            sigmets.value[i] = resp;
            console.log('sigmet updated', resp.ID);
        }
        console.log(sigmets.value);

    });
});

watchEffect(()=>{

    const timeLeft = auth.value.expires - Date.now();
    if(timeLeft < 1000*60*60*12) {
        refreshToken();
    }
    if(airports.value.length==0){
        aptBtn.value.bol = true;
        aptBtn.value.class = 'save';
    }

    socketState.airports = airports.value.map((current)=>{
        return current.ICAO;
    });
    if(!auth.value){
        router.push('/login');
    } else {
        socketState.authToken = auth.value.token;
    }

    //clean up old data:
    const now = Date.now();
    airports.value.forEach((elm,i,arr)=>{
        try{
            if(elm.windShear){
                if(Number(elm.windShear.validity.to)<now){
                    airports.value[i].windShear = null;
                }
            }
            if(elm.taf.latest){
                if(tafValidToDate(elm.taf.latest.validity.endDay,elm.taf.latest.validity.endHour)<now){
                    elm.taf.latest = null;
                }
            }
        } catch(err){
            console.error('issues cleaning up old data:',err);
        }
    });
    sigmets.value.forEach((elm,i,arr)=>{
        try {
            if(Number(elm.validTo) < now){
                console.log('sigmets removed',sigmets.value.ID);
                sigmets.value.splice(i,1);
            }
        } catch(err){
            console.error('issues cleanign old sigmets,',err);
        }
    });

    if(true){
        localStorage.setItem(STORAGE_KEY,
            JSON.stringify({
                airports: airports.value,
                radar: radarImage.value
            })
        );
    }
});

</script>

<template>
    <div class="row">
        <div class="col s12 xl7">
            <ul class="collection">
                <Airport
                    v-for="(item,index) in airports"
                    :airport="item"
                    :index="index"
                    :key="item.ICAO"
                    @deleteThis="deleteAirport"
                    ></Airport>
                <li ref="airportAdd" class="collection-item">
                    <div class="row">
                        <div v-if="aptBtn.bol" class="col input-field">
                            <input v-model="addICAO" 
                                id="addICAO" maxlength="4">
                            <label for="addICAO">ICAO code</label>
                        </div>
                        <div class="col">
                            <a ref="addAptBtn" class="btn-floating" @click="addAptBtn">
                                <i class="material-icons">{{ aptBtn.class }}</i>
                            </a>
                        </div>
                        <div class="col">
                            <a 
                                class="dropdown-trigger btn" 
                                data-target="defaultSelection"
                                
                                @click="loadPredefined('init')">Predefined</a>
                            <ul id="defaultSelection" class="dropdown-content">
                                <li
                                    v-if="predifinedList.length==0"
                                    class="progress"><div class="indeterminate">Loading...</div></li>
                                <li
                                    v-for="(apt) in predifinedList"
                                    :key="apt"><a @click="loadPredefined('populate',apt)">{{ apt.name }}</a></li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col s12 xl5">
            <Imagery
                :radarModel="radarImage" 
                :sigmets="sigmets">
            </Imagery>
        </div>
    </div>
</template>

<style scoped>
</style>
